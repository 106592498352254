.contact {
  min-height: 50vh;
  text-align: center;
  margin: 120px 0 80px 0;
}

.contact img {
  width: 160px;
  height: 140px;
  margin-bottom: 40px;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.contact-field {
  background-color: #021c1e;
  color: white;
  margin: 30px;
  width: 350px;
  min-height: 220px;
  padding: 24px;
  -webkit-box-shadow: 15px 15px 50px #ccc;
  box-shadow: 0px 0px 20px rgb(87, 87, 87);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-field h3 {
  font-size: 1.4rem;
  margin-bottom: 16px;
}

.contact-field p {
  margin: 8px 0;
}

.contact-field a {
  margin: 8px 0;
}

.service a {
  text-decoration: none;
  color: #25d366;
  margin: 40px 0;
  padding: 5px;
}

.service a:hover {
  color: #00ff5e;
  transition: all 0.3s ease-out;
}
