.footer {
  height: 350px;
  background-color: #021c1e;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: #fff;
  text-align: center;
}

.footer-right img {
  height: 140px;
  width: 150px;
}

.footer-left {
  display: flex;
}

.footer-list {
  list-style: none;
}

.footer-list li {
  margin: 20px 0px;
  text-align: center;
}

.footer-list li a:hover {
  transition: all 0.3s ease-out;
}

.footer-list li a.fb:hover {
  color: #1a4094;
}

.footer-list li a.ig:hover {
  color: #7926a8;
}
