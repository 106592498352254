.about-us{
    min-height: 50vh;
    text-align: center;
    margin: 120px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-container{
    width: 80%;
    color: white;
    background-color: #021C1E;
    padding: 50px;
    border-radius: 20px;
}

.about-us h1{
    margin-bottom: 30px;
    font-size: 48px;
}

.about-us p{
    margin-bottom: 30px;
    font-size: 26px;
}