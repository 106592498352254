:root{
    --back-color: #021C1E;
    --font-color: #fff;
  }

.navbar{
    background-color: var(--back-color);
    height: 60px;
    color: var(--font-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

nav .nav-links{
    list-style: none;
    display: flex;
}

.nav-link::before,
.nav-link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.nav-link::before {
    content: '';
    /* show by default */
}


nav .nav-link{
    text-decoration: none;
    color: var(--font-color);
    /* padding: 0 5px; */
    margin: 0 10px;
    cursor: pointer;
    position: relative;
}

.nav-link::before {
    transform-origin: 50% 100%;
    transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
}

.nav-link:hover::before {
    transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
    clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
}

.nav-link span {
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
}

.nav-link:hover span {
    transform: translate3d(0, -2px, 0);
}

