.book-preview{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0px;
}

.book-preview-container{
    color: white;
    background-color: #021C1E;
    min-height: 100vh;
    width: 80%;
    padding: 30px;
    text-align: center;
    border-radius: 20px;
    display: flex;
}

.book-preview-right{
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.book-preview-right img{
    width: 100%;
    border-radius: 8px;
}

.book-preview-right img:hover{
    -webkit-box-shadow: 10px 10px 5px #ccc;
    box-shadow: 0px 0px 20px rgb(87, 87, 87);
}

.book-preview-left{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-preview-left-info h1{
    margin-bottom: 32px;
    font-weight: 500;
}

.book-preview-left-info h3{
    margin-bottom: 16px;
    font-weight: 500;
}


