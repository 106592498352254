.addbook{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0px;
}

.addbook-container{
    color: white;
    background-color: #021C1E;
    min-height: 100vh;
    width: 70%;
    padding: 30px;
    text-align: center;
    border-radius: 20px;
}

.addbook-form{
    background-color: white;
    margin: 20px 0px;
    padding: 20px 30px;
    border-radius: 5px;
}

.addbook-inp{
    display: block;
    resize: none;
    width: 100%;
    margin: 20px 0px;
    padding: 10px 15px;
    font-size: 1.2rem;
    color: #021C1E;
    border-radius: 10px;
    border: solid 1px #021C1E;
}

.addbook-inp:focus{
    outline: none;
}

.addbook-btn{
    width: 100%;
    padding: 10px 15px;
    font-size: 1.2rem;
    background-color: #021C1E;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.addbook-btn:hover{
    background-color: #042629;
    color: white;
}

.addbook-btn-control{
    font-size: 1.2rem;
    padding: 8px 12px;
    margin: 20px 0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #04AA6D;
    color: white;
    transition-duration: 0.4s;
}
.addbook-btn-control:hover{
    background-color: #0dbb7b;
}