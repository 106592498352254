.service {
  text-align: center;
  width: 250px;
}

.service h1 {
  margin: 15px 0px;
}

.service p {
  margin-bottom: 20px;
  margin-top: 15px;
}

.wtsp {
  text-decoration: none;
  color: #25d366;
  margin: 20px 0;
  padding: 5px;
}

.wtsp:hover {
  color: #00ff5e;
  transition: all 0.3s ease-out;
}
