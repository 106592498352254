.editions {
  min-height: 50vh;
  background-color: #e8f7ee;
  padding-top: 70px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
