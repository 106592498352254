.font-services {
  margin-bottom: 30px;
  font-size: 26px;
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-container img {
  width: 600px;
  height: auto;
}
