.books-container{
    min-height: 100vh;
    background-color: #021C1E;
    margin: 100px 80px;
    border-radius: 20px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    padding: 30px;
    /* width: 80%; */
    /* align-items: center; */
}

.books-container h2{
    margin: 20px 0 30px 0;
}


.books-container-img-link img{
    width: 100px;
    object-fit: cover;
    height: 100%;
    width: 200px;
    margin: 20px 25px;
    height: 280px;
}

.books-container-img-link img:hover{
    -webkit-box-shadow: 15px 15px 50px #ccc;
    box-shadow: 0px 0px 20px rgb(87, 87, 87);
}