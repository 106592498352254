.hero{
    min-height: 100vh;
    background-color: #E8F7EE;
    color: #021C1E;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
}

.cards-container{
    width: 80%;
    white-space: nowrap;
    scrollbar-width: none;
    scroll-behavior: smooth;
    overflow: hidden;
    display: flex;
    margin: 65px;
}

.slider-icon{
    background-color: #E8F7EE;
    border-radius: 100%;
    position: absolute;
    cursor: pointer;
    opacity: 0.7;
    box-shadow: 2px 2px 2px 2px rgb(0, 0, 0, 12%);
}

.slider-icon:hover{
    opacity: 1;
    transition: opacity ease-out 0.1s;
}

.slider-icon.right{
    right: 40px;
    margin-left: 200px;
}

.slider-icon.left{
    left: 40px;
}

.cards-container::-webkit-scrollbar{
    display: none;
}

@media only screen and (max-width: 640px) {
    .hero{
        height: 100%;
    }
  }