.editbook{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0px;
}

.editbook-container{
    color: white;
    background-color: #021C1E;
    min-height: 100vh;
    width: 70%;
    padding: 30px;
    text-align: center;
    border-radius: 20px;
}