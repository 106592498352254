.conditions {
  min-height: 50vh;
  text-align: center;
  margin: 120px 0 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.conditions-card {
  background-color: #021c1e;
  color: white;
  margin: 50px;
  width: 450px;
  min-height: 500px;
  padding: 30px;
  -webkit-box-shadow: 15px 15px 50px #ccc;
  box-shadow: 0px 0px 20px rgb(87, 87, 87);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conditions-card h3 {
  font-size: 1.6rem;
  margin-bottom: 16px;
}

.conditions-card p {
  margin: 8px 0;
}

.cond-link {
  background-color: rgb(226, 223, 223);
  color: #021c1e;
  padding: 8px 12px;
  margin: 14px 0;
}
.cond-link:hover {
  background-color: #021c1e;
  color: #caf7dc;
  transition: all 0.3s ease-out;
}
