
* {
  font-family: 'Tajawal', sans-serif;
  box-sizing: border-box;
  margin: 0;
}


.link{
  text-decoration: none;
  color: #fff;
}

.notFound{
  min-height: 100vh;
  text-align: center;
}

.notFound h1{
  margin-top: 45vh;
}

.private-loading{
  margin: 40vh 0;
  text-align: center;
}

