.products {
  background-color: #021c1e;
  width: 90%;
  color: #fff;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgb(87, 87, 87);
  margin-bottom: 80px;
  position: relative;
}

.products h2 {
  font-size: 2rem;
  margin-top: 40px;
  margin-bottom: 20px;
}

.products-container {
  margin-bottom: 60px;
}

.products-container img {
  object-fit: cover;
  width: 200px;
  margin: 0 20px;
  height: 280px;
}

.products-container img:hover {
  -webkit-box-shadow: 15px 15px 50px #ccc;
  box-shadow: 0px 0px 20px rgb(87, 87, 87);
}

.products-more {
  padding: 10px 40px;
  /* color: #E8F7EE; */
  color: #021c1e;
  /* border: solid 3px #021C1E; */
  border-radius: 8px;
  background-color: #e8f7ee;
  margin-bottom: 40px;
  position: absolute;
  bottom: -60px;
  right: 50%;
  margin-right: -64px;
}

.products-more:hover {
  background-color: #021c1e;
  color: #e8f7ee;
  transition: all 0.3s ease-out;
}
