.login-page{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form input{
    display: block;
    resize: none;
    width: 400px;
    margin: 20px 0px;
    padding: 10px 15px;
    font-size: 1.2rem;
    color: #021C1E;
    border-radius: 10px;
    border: solid 1px #021C1E;
}

.login-form input:focus{
    outline: none;
}

.login-form button{
    width: 100%;
    padding: 10px 15px;
    font-size: 1.2rem;
    background-color: #021C1E;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.login-form button:hover{
    background-color: #042629;
    color: white;
}