.bookslist{
    min-height: 100vh;
}

.bookslist-container{
    margin-top: 100px;
    padding: 0px 50px;
}

.bookslist-btn-add{
    font-size: 1.2rem;
    padding: 8px 12px;
    margin: 0 0 20px 0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #04AA6D;
    color: white;
    transition-duration: 0.4s;
}
.bookslist-btn-add:hover{
    background-color: #0dbb7b;
}

.bookslist-btn-logout{
    font-size: 1.2rem;
    padding: 8px 12px;
    margin: 0 0 20px 0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(161, 16, 16);
    color: white;
    transition-duration: 0.4s;
    margin-right: 10px;
}
.bookslist-btn-logout:hover{
    background-color: #741919;
}

table{
    width: 100%;
    border-collapse: collapse;
}

th{
    background-color: #04AA6D;
    color: white;
    border: 1px solid #ddd;
    padding: 12px;
    font-weight: 600;
}

tr, td{
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.bookslist-btn.delete, .bookslist-btn.modify{
    padding: 6px 8px;
    margin: 0px 4px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.4s;
}

.bookslist-btn.delete{
    background-color: #f44336;
}
.bookslist-btn.modify{
    background-color: #4CAF50;
}

.bookslist-btn:hover{
    background-color: #021C1E;
    color: white;
}



tr:nth-child(even){
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #ddd;
}