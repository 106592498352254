.search {
  background-color: #e8f7ee;
  padding: 70px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search form {
  position: relative;
}

.search input {
  padding: 8px 12px;
  width: 300px;
  font-size: 1.2rem;
  color: #021c1e;
  border-radius: 10px;
  border: solid 1px #021c1e;
  outline: none;
}

.addbook-inp:focus {
  outline: none;
}

.search button {
  position: absolute;
  left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #021c1e;
  color: #fff;
  border: none;
  height: 100%;
  width: 44px;
  cursor: pointer;
}

.search button:hover {
  background-color: #193b3d;
}

.search-results {
  margin-top: 50px;
  background-color: #021c1e;
  width: 90%;
  color: #fff;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgb(87, 87, 87);
  margin-bottom: 80px;
}

.search-results h2 {
  font-size: 2rem;
  margin: 40px 0;
}

.search-results img {
  object-fit: cover;
  width: 200px;
  margin: 0 0 30px 0;
  height: 280px;
}

.search-results img:hover {
  -webkit-box-shadow: 15px 15px 50px #ccc;
  box-shadow: 0px 0px 20px rgb(87, 87, 87);
}
