.card {
  background-color: #021c1e;
  padding: 40px 50px;
  border-radius: 10px;
  margin: 0 20px;
}

.card-img {
  height: 460px;
  width: 310px;
  border-radius: 10px;
  border: 2px solid #e8f7ee;
  margin: 0 auto;
  cursor: pointer;
}

.card-book-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
